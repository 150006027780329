.m-tribe {
  align-items: center;
  background-color: var(--peach-cream);
  display: flex;
  flex-direction: column;
  height: 1375px;
  padding: 34px 0;
  width: 375px;
}

.vector-1 {
  cursor: pointer;
  height: 70px;
  left: 23px;
  position: fixed;
  top: 34px;
  width: 209px;
  z-index: 6;
}

.pictures-1 {
  cursor: pointer;
  height: 199px;
  margin-top: 81px;
  opacity: 0;
  overflow: hidden;
  position: relative;
  width: 355px;
  z-index: 1;
}

.pictures-1.animate-enter1 {
  animation: animate-enter1-frames 0.20s ease-in-out 0.00s 1 normal forwards;
  opacity: 0;
}

.post-peyote {
  height: 199px;
  left: 10px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 355px;
}

.tribu-provides-a-nut {
  letter-spacing: 0;
  margin-right: 28.0px;
  margin-top: 18px;
  min-height: 48px;
  width: 309px;
  z-index: 2;
}

.image-5 {
  height: 178px;
  margin-left: 89.0px;
  margin-top: 18px;
  object-fit: cover;
  width: 154px;
  z-index: 3;
}

.image-7 {
  height: 171px;
  margin-left: 55.0px;
  margin-top: 217px;
  object-fit: cover;
  width: 258px;
  z-index: 4;
}

.image-8 {
  height: 153px;
  margin-left: 1.0px;
  margin-top: 45px;
  object-fit: cover;
  width: 132px;
  z-index: 5;
}

@keyframes animate-enter1-frames {
  from{opacity: 0;
}
to{opacity: 1;
}
}

