.pictures-4 {
  align-self: center;
  height: 208px;
  margin-top: 81px;
  opacity: 0;
  overflow: hidden;
  position: relative;
  width: 355px;
  z-index: 1;
}

.pictures-4.animate-enter4 {
  animation: animate-enter4-frames 0.20s ease-in-out 0.00s 1 normal forwards;
  opacity: 0;
}

.casita-1 {
  height: 208px;
  left: 10px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 355px;
}

@keyframes animate-enter4-frames {
  from{opacity: 0;
}
to{opacity: 1;
}
}

