.m-ceremonies {
  align-items: center;
  background-color: var(--peach-cream);
  display: flex;
  flex-direction: column;
  height: 1375px;
  padding: 34px 0;
  width: 375px;
}

.vector-5 {
  cursor: pointer;
  height: 70px;
  left: 23px;
  position: fixed;
  top: 34px;
  width: 209px;
  z-index: 3;
}

.pictures-5 {
  cursor: pointer;
  height: 241px;
  margin-top: 81px;
  opacity: 0;
  overflow: hidden;
  position: relative;
  width: 355px;
  z-index: 1;
}

.pictures-5.animate-enter5 {
  animation: animate-enter5-frames 0.20s ease-in-out 0.00s 1 normal forwards;
  opacity: 0;
}

.grandmother-stars {
  height: 241px;
  left: 10px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 355px;
}

.overlap-group-4 {
  height: 378px;
  margin-right: 15.0px;
  margin-top: 11px;
  position: relative;
  width: 314px;
  z-index: 2;
}

.outdoor-ceremonies-a {
  left: 0;
  letter-spacing: 0;
  position: absolute;
  top: 0;
  width: 310px;
}

.the-mild-climate-it {
  left: 0;
  letter-spacing: 0;
  position: absolute;
  top: 158px;
  width: 310px;
}

.l1050509-1 {
  height: 123px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 48px;
  width: 314px;
}

@keyframes animate-enter5-frames {
  from{opacity: 0;
}
to{opacity: 1;
}
}

