:root { 
  --black: #000000;
  --peach-cream: #fceedf;
 
  --font-size-m: 14px;
  --font-size-s: 12px;
 
  --font-family-inter: "Inter", Helvetica;
  --font-family-roboto: "Roboto", Helvetica;
}
.roboto-normal-black-14px {
  color: var(--black);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.inter-normal-black-12px {
  color: var(--black);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}
