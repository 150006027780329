.m-landing {
  align-items: flex-start;
  background-color: var(--peach-cream);
  display: flex;
  height: 1482px;
  padding: 34px 0;
  width: 375px;
}

.pictures {
  height: 1263px;
  margin-top: 81px;
  opacity: 0;
  position: relative;
  width: 375px;
  z-index: 1;
}

.pictures.animate-enter {
  animation: animate-enter-frames 0.20s ease-in-out 0.00s 1 normal forwards;
  opacity: 0;
}

.overlap-group1 {
  height: 449px;
  left: 0;
  position: absolute;
  top: 0;
  width: 375px;
}

.link {
  cursor: pointer;
  height: 210px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 375px;
}

.dji_0098-1 {
  cursor: pointer;
  height: 239px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 210px;
  width: 375px;
}

.casita {
  cursor: pointer;
  height: 208px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 448px;
  width: 375px;
}

.overlap-group {
  height: 397px;
  left: 0;
  position: absolute;
  top: 657px;
  width: 375px;
}

.aerial-overlay {
  cursor: pointer;
  height: 187px;
  left: 0;
  position: absolute;
  top: 210px;
  width: 375px;
}

.image-1 {
  cursor: pointer;
  height: 210px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 1053px;
  width: 375px;
}

.vector {
  height: 70px;
  left: 23px;
  position: fixed;
  top: 34px;
  width: 209px;
  z-index: 2;
}

@keyframes animate-enter-frames {
  from{opacity: 0;
}
to{opacity: 1;
}
}

