.m-naturalsetting {
  align-items: center;
  background-color: var(--peach-cream);
  display: flex;
  flex-direction: column;
  height: 1375px;
  overflow: hidden;
  width: 375px;
}

.vector-3 {
  cursor: pointer;
  height: 70px;
  left: 23px;
  position: fixed;
  top: 34px;
  width: 209px;
  z-index: 9;
}

.pictures-3 {
  cursor: pointer;
  height: 239px;
  margin-top: 115px;
  opacity: 0;
  overflow: hidden;
  position: relative;
  width: 355px;
  z-index: 1;
}

.pictures-3.animate-enter3 {
  animation: animate-enter3-frames 0.20s ease-in-out 0.00s 1 normal forwards;
  opacity: 0;
}

.dji_0098-1-1 {
  height: 239px;
  left: 2px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 355px;
}

.tribu-is-located-on-1 {
  letter-spacing: 0;
  margin-top: 11px;
  min-height: 37px;
  width: 347px;
  z-index: 2;
}

.image-9 {
  height: 196px;
  margin-right: 3.0px;
  margin-top: 1px;
  object-fit: cover;
  width: 342px;
  z-index: 8;
}

.overlap-group1-1 {
  height: 215px;
  margin-right: 4.0px;
  margin-top: 7px;
  position: relative;
  width: 347px;
  z-index: 5;
}

.img_4127-1 {
  height: 192px;
  left: 3px;
  object-fit: cover;
  position: absolute;
  top: 23px;
  width: 342px;
}

.at-the-base-of-the-c {
  left: 0;
  letter-spacing: 0;
  position: absolute;
  top: 0;
  width: 347px;
}

.the-desert-climate-i {
  letter-spacing: 0;
  margin-top: 19px;
  min-height: 37px;
  width: 347px;
  z-index: 7;
}

.widget-wrapper {
  align-items: flex-start;
  display: flex;
  margin-top: 12px;
  width: 355px;
  z-index: 6;
}

.sunrise {
  object-fit: cover;
  width: 100%;
}

.overlap-group-2 {
  height: 216px;
  margin-top: 19px;
  position: relative;
  width: 355px;
  z-index: 3;
}

.img_4810-1 {
  height: 186px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 30px;
  width: 355px;
}

.every-sunrise-and-su {
  left: 4px;
  letter-spacing: 0;
  position: absolute;
  top: 0;
  width: 328px;
}

.dsc_9274-1 {
  height: 1px;
  margin-right: 180.5px;
  margin-top: 192px;
  object-fit: cover;
  width: 1px;
  z-index: 4;
}

@keyframes animate-enter3-frames {
  from{opacity: 0;
}
to{opacity: 1;
}
}

