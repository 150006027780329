@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");

@import url("https://fonts.googleapis.com/css?family=Roboto:400|Inter:400");

/* The following line is used to measure usage of this code in production. For more info see our usage billing page */
@import url("https://px.animaapp.com/6233d08d1875ee98c055d552.6233d08fdacf5d485e53625b.qq4TeDM.rcp.png");

.screen a {
  display: contents;
  text-decoration: none;
}

.container-center-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  pointer-events: none;
  width: 100%;
}

.container-center-horizontal > * {
  flex-shrink: 0;
  pointer-events: auto;
}

.align-self-flex-center {
  align-self: flex-center;
}

* {
  box-sizing: border-box;
}
