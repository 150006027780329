.m-facilities {
  align-items: flex-start;
  background-color: var(--peach-cream);
  display: flex;
  flex-direction: column;
  height: 1375px;
  padding: 34px 0;
  position: relative;
  width: 375px;
}

.vector-4 {
  cursor: pointer;
  height: 70px;
  left: 23px;
  position: fixed;
  top: 34px;
  width: 209px;
  z-index: 6;
}

.img_-container {
  align-items: flex-start;
  align-self: center;
  display: flex;
  margin-top: 11px;
  min-width: 355px;
  z-index: 3;
}

.img_0519-1 {
  height: 135px;
  object-fit: cover;
  width: 178px;
}

.img_0481-1 {
  height: 135px;
  object-fit: cover;
  width: 177px;
}

.overlap-group-3 {
  align-self: center;
  height: 263px;
  margin-left: 4.0px;
  margin-top: 14px;
  position: relative;
  width: 351px;
  z-index: 2;
}

.the-small-casita-on {
  left: 4px;
  letter-spacing: 0;
  position: absolute;
  top: 0;
  width: 303px;
}

.x39104-f07-335-e-4-c2 {
  height: 202px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 61px;
  width: 351px;
}

.an-rv-with-accomodat {
  letter-spacing: 0;
  margin-left: 14px;
  margin-top: 14px;
  min-height: 37px;
  width: 303px;
  z-index: 4;
}

.overlap-group1-2 {
  height: 171px;
  margin-left: 15px;
  margin-top: 18px;
  position: relative;
  width: 251px;
  z-index: 5;
}

.additional-accomodat {
  left: 0;
  letter-spacing: 0;
  position: absolute;
  top: 0;
  width: 248px;
}

.kite-ventana {
  cursor: pointer;
  left: 0;
  letter-spacing: 0;
  position: absolute;
  top: 37px;
  width: 248px;
}

.casa-vista-cerralvo {
  cursor: pointer;
  left: 3px;
  letter-spacing: 0;
  position: absolute;
  top: 98px;
  width: 248px;
}

