.m-location {
  align-items: flex-start;
  background-color: var(--peach-cream);
  display: flex;
  flex-direction: column;
  height: 1375px;
  padding: 34px 0;
  width: 375px;
}

.vector-6 {
  cursor: pointer;
  height: 70px;
  left: 23px;
  position: fixed;
  top: 34px;
  width: 209px;
  z-index: 7;
}

.pictures-6 {
  align-self: center;
  cursor: pointer;
  height: 187px;
  margin-top: 81px;
  opacity: 0;
  overflow: hidden;
  position: relative;
  width: 355px;
  z-index: 1;
}

.pictures-6.animate-enter6 {
  animation: animate-enter6-frames 0.20s ease-in-out 0.00s 1 normal forwards;
  opacity: 0;
}

.aerial-overlay-1 {
  height: 187px;
  left: 10px;
  position: absolute;
  top: 0;
  width: 355px;
}

.the-culture-and-desi {
  align-self: center;
  letter-spacing: 0;
  margin-right: 25.0px;
  margin-top: 11px;
  min-height: 42px;
  width: 310px;
  z-index: 2;
}

.learning-to-live-wit {
  letter-spacing: 0;
  margin-left: 20px;
  margin-top: 36px;
  min-height: 69px;
  width: 95px;
  z-index: 3;
}

.the-physical-structu {
  letter-spacing: 0;
  margin-left: 20px;
  margin-top: 108px;
  min-height: 69px;
  width: 95px;
  z-index: 4;
}

.personal-places-to-meditate-and-connect {
  letter-spacing: 0;
  margin-left: 20px;
  margin-top: 89px;
  min-height: 69px;
  width: 95px;
  z-index: 5;
}

.a-place-to-create-e {
  letter-spacing: 0;
  margin-left: 20px;
  margin-top: 132px;
  min-height: 69px;
  width: 95px;
  z-index: 6;
}

@keyframes animate-enter6-frames {
  from{opacity: 0;
}
to{opacity: 1;
}
}

