.mobile {
  align-items: flex-start;
  background-color: var(--peach-cream);
  display: flex;
  flex-direction: column;
  height: 1088px;
  width: 375px;
}

.frame-1 {
  height: 125px;
  width: 375px;
}

.pictures-7 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 940px;
  overflow: hidden;
  width: 375px;
}

.post-peyote-1 {
  height: 227px;
  width: 375px;
}

.grandmother-stars-1 {
  height: 262px;
  width: 375px;
}

.casita-2 {
  height: 260px;
  width: 375px;
}

.sunrise {
  height: 191px;
  margin-top: 20px;
  width: 375px;
}

