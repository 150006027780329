.m-laventana {
  align-items: center;
  background-color: var(--peach-cream);
  display: flex;
  flex-direction: column;
  height: 1375px;
  padding: 34px 0;
  width: 375px;
}

.vector-2 {
  cursor: pointer;
  height: 70px;
  left: 23px;
  position: fixed;
  top: 34px;
  width: 209px;
  z-index: 3;
}

.pictures-2 {
  background-position: 50% 50%;
  background-size: cover;
  cursor: pointer;
  height: 189px;
  margin-top: 81px;
  opacity: 0;
  width: 355px;
  z-index: 1;
}

.pictures-2.animate-enter2 {
  animation: animate-enter2-frames 0.20s ease-in-out 0.00s 1 normal forwards;
  opacity: 0;
}

.overlap-group-1 {
  height: 273px;
  margin-right: 9.0px;
  margin-top: 9px;
  position: relative;
  width: 332px;
  z-index: 2;
}

.tribu-is-located-on {
  left: 3px;
  letter-spacing: 0;
  position: absolute;
  top: 0;
  width: 329px;
}

.the-gulf-of-californ {
  left: 0;
  letter-spacing: 0;
  position: absolute;
  top: 72px;
  width: 329px;
}

@keyframes animate-enter2-frames {
  from{opacity: 0;
}
to{opacity: 1;
}
}

